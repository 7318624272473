import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../../notifications/notifications.service';

import { DadoSensor } from './dado-sensor.model';
import { DadoSensorService } from './dado-sensor.service';

import {
  LoadDadoSensores, 
  DadoSensoresLoaded, 
  DadoSensoresFailed,
  DadoSensorActionTypes,
  ReportDadoSensor,
  DadoSensorReported
} from './dado-sensor.actions';
import { DadoSensorState } from './dado-sensor.reducer';
import { DadoSensorFacade } from './dado-sensor.facade';
import { NavigateLinkService } from '../../components/pcd/navigate-link.service';
import { SensorFacade } from '../sensor/sensor.facade';
import { IntervaloFacade } from '../intervalo/intervalo.facade';
import { UfFacade } from '../uf/uf.facade';
import { InstituicaoFacade } from '../instituicao/instituicao.facade';

@Injectable({providedIn: 'root'})
export class DadoSensorEffects 
{
  @Effect()
  report$ = this.dataPersistence.fetch(DadoSensorActionTypes.ReportDadoSensor, {
    run: (action: ReportDadoSensor, state: DadoSensorState) => {

      return this.dadoSensorService
        .report(action.payload.estacao_id, 
          action.payload.sensor_id, 
          action.payload.periodo, 
          action.payload.intervalo, 
          action.payload.operador,
          action.payload.data,
          action.payload.valor,
          action.payload.instituicao_nome,
          action.payload.instituicao_id)
        .pipe(
          map((res) => {
            if (res['failure'])
              this.notifyError.emit('Erro ao reportar problema.');
            else    
              this.notifyError.emit('Dado reportado com sucesso!');
            
            return new DadoSensorReported(res);
          })
        )
    },

    onError: (action: ReportDadoSensor, error) => {
      this.notifyError.emit(error.message);
      return new DadoSensorReported({ failure: true });
    }
  });

  @Effect()
  load$ = this.dataPersistence.fetch(DadoSensorActionTypes.LoadDadoSensores, {
    run: (action: LoadDadoSensores, state: any) => {
      
      if(action.payload.refresh == false && this.isValid(state, action.payload.ufs, action.payload.instituicaoIds, action.payload.operador))
        return new DadoSensoresLoaded(state.dadosSensor);
    
      return this.dadoSensorService
        .all(
          action.payload.sensorId, 
          action.payload.instituicaoIds, 
          action.payload.ufs, 
          action.payload.intervalo, 
          action.payload.periodo,
          action.payload.operador)
        .pipe(
          map((res: DadoSensor[]) => {

            const requestParams = res['meta']['request']['query_params']['filter_list'];

            let queueIn = res['meta']['cache']['queue_in'];
            let expiresIn = res['meta']['cache']['expires_in'];
            let cachedAt = new Date(res['meta']['cache']['cached_at']['date']);
            let unidade = res['data']['unidade']
            let dadosSensores = res['data']['list'].filter(value => value.valor != null).map(value => ({
                  ...value,
                  unidade: unidade
                }));
            if (queueIn > expiresIn)
              queueIn = expiresIn;
            if (queueIn < 0)
              queueIn = 30;

            let dateQueue = new Date()
            dateQueue.setSeconds(dateQueue.getSeconds() + queueIn);

            this.dadoSensorFacade.dadoSensoresQueueIn(dateQueue);
            this.dadoSensorFacade.dadoSensoresCachedAt(cachedAt);

            this.sensorFacade.soughtSensor(Number(requestParams['sensor']));
            this.sensorFacade.changeOperador(requestParams['operador']);
            this.intervaloFacade.soughtIntervalo(requestParams['intervalo']);
            this.intervaloFacade.soughtPeriodo(requestParams['periodo']);
            this.ufFacade.sought(requestParams['uf'].split(','));
            this.instituicaoFacade.sought(requestParams['instituicao'].split(',').map(value => parseInt(value)));
            
            this.navigateLinkService.navigate(action.payload);

            return new DadoSensoresLoaded(dadosSensores);
          })
        );
    },

    onError: (action: LoadDadoSensores, error) => {
      this.notifyError.emit(error.message);
      return new DadoSensoresFailed();
    }
  });

  // {"message":"SQLSTATE[42601]: Syntax error: 7 ERRO:  erro de sintaxe em ou pr\u00f3ximo a \")\"\nLINE 8: AND dado_sensor.dse_estacao IN ()\n                                                                
  //  ^ (SQL: SELECT ds_select.dse_estacao, AVG(ds_select.valor) as valor, ds_select.dse_sensor\n                   
  //   FROM (\n
  //   SELECT dse_estacao, CAST(dse_data AS DATE) AS dia, AVG(dado_sensor.dse_valor) as valor, dse_sensor \n
  //     FROM pcd.dado_sensor dado_sensor \n                        
  //     WHERE dado_sensor.dse_sensor = 2\n
  //     AND dado_sensor.dse_data >= '2020-07-09 20:01:00' \n 
  //     AND dado_sensor.dse_data <= '2020-07-10 20:01:00'\n
  //     AND dado_sensor.dse_estacao IN ()\n
  //     AND ((dado_sensor.dse_qualidade IS NULL OR dado_sensor.dse_qualidade <> 9)) \n
  //     GROUP BY dia, dse_estacao, dse_sensor\n                                                  ) AS ds_select\n                    
  //     GROUP BY ds_select.dse_estacao, ds_select.dse_sensor)"}

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<DadoSensorState>,
    private dadoSensorService: DadoSensorService,
    private sensorFacade: SensorFacade,
    private intervaloFacade:IntervaloFacade,
    private dadoSensorFacade: DadoSensorFacade,
    private ufFacade: UfFacade,
    private instituicaoFacade: InstituicaoFacade,
    private notifyError: NotificationsService,
    private navigateLinkService: NavigateLinkService
  ) {}

  private isValid(state, selectedUfs, selectedInstituicoes,selectedOperador){
    return state.sensor.soughtSensorId == state.sensor.selectedSensorId &&
      state.intervalo.soughtIntervaloId == state.intervalo.selectedIntervaloId &&
      state.intervalo.soughtPeriodo == state.intervalo.selectedPeriodo &&
      state.uf.soughtUfIds == selectedUfs &&
      state.instituicao.soughtInstituicaoIds == selectedInstituicoes &&
      state.sensor.operador == selectedOperador &&
      state.dadosSensor.queue_in.getTime() > new Date().getTime();
  }
}
