import { Component, OnInit, Input, ElementRef, Directive } from '@angular/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { Sensor } from '../../../state/sensor/sensor.model';
import { EstacaoSerieFacade } from '../../../state/estacao-serie/estacao-serie.facade';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { SensorSerie, EstacaoSerie } from '../../../state/estacao-serie/estacao-serie.model';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.css']
})
export class ChartLineComponent implements OnInit {

  chart = [];

  @Input() target: string;
  @Input() colors;
  @Input() estacaoId: number;
  @Input() sensorIds: number[];
  @Input() periodo: string;
  @Input() type: string = 'line';

  public targetDiv: string;

  private sensores: Sensor[];
  private estacaoSeries: EstacaoSerie[];

  public loading = false;
  private ploted = false;


  constructor(
    private estacaoSerieFacade: EstacaoSerieFacade,
    private sensorFacade: SensorFacade,
    private elementRef:ElementRef)
  {
    this.targetDiv = 'div-' + this.target;
  }

  public reload(estacaoId: number, periodo: string)
  {
    this.estacaoId = estacaoId;
    this.periodo = periodo;
    this.clearCanvas();
    this.pick();
  }

  private clearCanvas()
  {
    let _canvas = this.elementRef.nativeElement.querySelector('canvas');
    while (_canvas = this.elementRef.nativeElement.querySelector('canvas')) {
      _canvas.remove();
      _canvas = this.elementRef.nativeElement.querySelector('canvas');
    }
  }

  private pick(){
    this.loading = true;
    this.ploted = false;

    this.estacaoSerieFacade.pickEstacaoSerie({
      estacaoId: this.estacaoId,
      sensores: this.sensorIds,
      periodo: this.periodo
    });
  }

  private plot(sensorSeries)
  {
    if (sensorSeries.length && sensorSeries[0].series.length)
      this.createCanvas(sensorSeries, this.sensores);

    this.ploted = true;
    this.loading = false;
  }

  ngOnInit() {

    this.sensorFacade.all$.subscribe(
      sensores => {
        this.sensores = sensores.filter(sensor => this.sensorIds.includes(Number(sensor.id)));
      }
    );

    this.estacaoSerieFacade.allEstacaoSeries$.subscribe(estacaoSerie => {

      this.estacaoSeries = estacaoSerie;

      let _estacaoSerie = this.estacaoSeries.filter(value => value && value.id == this.estacaoId + ':' + this.sensorIds.join(',') + ':' + this.periodo);

      if (_estacaoSerie.length == 0 && !this.loading)
        this.pick();
      else if (!this.ploted && _estacaoSerie[0])
        this.plot(_estacaoSerie[0].estacaoSeries.filter(serie => this.sensorIds.includes(serie.name)));

    });
  }

  getLabels(data) {
    let labels;
    for (const d of data) {
      labels = d.labels;
    }

    return labels;
  }

  getResults(results, colors, combo_chart = false) {

    const result = [];
    results.forEach((item, index) => {
      result.push(
        {
          data: item.data,
          borderColor: colors[index],
          fill: false,
          label: item.label,
          backgroundColor: colors[index],
        }
      );
    });
    return result;
  }

  private getDatasets(series, sensores)
  {
    let datasets = [];

    sensores.forEach((sensor, index) => {

      if (!series[index])
        return;

      let color = this.colors[index];
      let serie = series[index].series;

      datasets.push({
        data: serie.map(item => {
          return {
            x: moment(item.name, moment.ISO_8601).toDate(),
            y: Number(item.value).toFixed(1)
          };
        }),
        borderColor: color,
        fill: false,
        label: `${sensor.descricao} (${sensor.unidade})`,
        type: this.type,
        backgroundColor: color,
      })
    });

    return datasets;
  }

  private createCanvas(series: SensorSerie[] | null, sensores: Sensor[] | null)
  {
    let interval = window.setInterval(() => {
      try {
        let _canvas = this.elementRef.nativeElement.querySelector('#' + this.target);
        if (_canvas)
          _canvas.remove();

        let _div = this.elementRef.nativeElement.querySelector('#' + this.targetDiv);
        _div.insertAdjacentHTML('afterbegin', '<canvas id="'+this.target+'" width="100%"></canvas>');

        this.draw(series, sensores);

        clearInterval(interval);
      } catch (error) {
        console.log('error');
      }
    }, 300);
  }

  private draw(series: SensorSerie[] | null, sensores: Sensor[] | null) {

    let dataSet = this.getDatasets(series, sensores);
    //BLOCO PARA REMOVER 3HORAS DOS DADOS TRAZIDOS E LOCALIZAR PARA GMT BRASIL
    //Só deve ser aplicado para caso de intervalo horário ou diário.
    if (this.periodo.includes('d') || this.periodo.includes('h'))
    {
      dataSet.forEach((dataSet, dataSetIndex) => {
        dataSet['data'].forEach((data, dataIndex) => {
          data['x'].setHours(data['x'].getHours() - 3);
          //dataSet[dataSetIndex]['data'][dataIndex]['x'].replace('');
        });
      });
    }

    this.chart = new Chart(this.target, {
      type: this.type,
      data: {
        labels: series[0].series.map(dado => dado.name),
        //datasets: this.getDatasets(series, sensores)
        datasets: dataSet
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time',
            distribution: 'series',
            ticks: {
              beginAtZero: false
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],
        },
        spanGaps: false,
        tooltips: {
          callbacks: {
            title: () => {
              return '';
            },
            beforeLabel: (tooltipItem, data) => {
              const dt = new Date(tooltipItem.xLabel);

              if (this.periodo.includes('d') || this.periodo.includes('h'))
              {
                return ('00' + dt.getDate().toString()).slice(-2) + '/' + ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
                  '/' + dt.getFullYear() + ' ' + ('00' + dt.getHours().toString()).slice(-2) + ':' +
                  ('00' + dt.getMinutes().toString()).slice(-2);
              }
              else
              {
                return ('00' + dt.getDate().toString()).slice(-2) + '/' + ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
                '/' + dt.getFullYear();
              }
            }
          }
        },
        zoom: {
          // Boolean to enable zooming
          enabled: true,

          // Enable drag-to-zoom behavior
          drag: true,

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: 'xy',
          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null
          }
        }
      }
    });
  }
}
