export interface DadoSensor {
    codigo: number,
    codigo_origem: string,
    geom: any,
    instituicao: string,
    municipio: string,
    nome: string,
    sensor: string,
    valor: string,
    unidade: string
}

export const emptyDadoSensor: DadoSensor = {
    codigo: null,
    codigo_origem: null,
    geom: null,
    instituicao: null,
    municipio: null,
    nome: null,
    sensor: null,
    valor: null,
    unidade: null
}

export interface DadoSensorPayload {
    instituicaoIds: number[];
    sensorId: number;
    ufs: string[],
    intervalo: string,
    periodo: string,
    operador: string,
    estacao: number,
    refresh: boolean
}

export const emptyDadoSensorPayload: DadoSensorPayload = {
    instituicaoIds: [],
    sensorId:       null,
    ufs:            [],
    intervalo:      null,
    periodo:        null,
    operador:       null,
    estacao:        null,
    refresh:        false
}
