import { Component, OnInit, ViewChild } from '@angular/core';
import { MapCoreComponent } from '../map-core/map-core.component';
import { DadoSensorFacade } from 'libs/core-pcd/src/lib/state/dado-sensor/dado-sensor.facade';

import { MapPcdMarkerFactory } from '../map-pcd-marker/map-pcd-marker.factory';
import { DadoSensor } from '../../../state/dado-sensor/dado-sensor.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { ConfigFacade } from '../../../state/config/config.facade';
import { ViewMode } from '../../../state/config/config.model';
import ContextMenu from 'ol-contextmenu/dist/ol-contextmenu.js';
import { NavigateLinkService } from '../../pcd/navigate-link.service';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';


@Component({
  selector: 'app-map-pcd',
  templateUrl: './map-pcd.component.html',
  styleUrls: ['./map-pcd.component.css']
})
export class MapPcdComponent implements OnInit {

  public mapOptions: any;
  public estacoes: DadoSensor[];
  private instituicoesSought;
  private instituicao;
  private contextMenuSelectedItem: DadoSensor = null;
  private dadoSensorCachedAt: Date;

  private viewMode: ViewMode = ViewMode.LARGE;

  @ViewChild('map', {static: true}) map: MapCoreComponent;

  constructor(
    private dadoSensorFacade: DadoSensorFacade,
    private instituicaoFacade: InstituicaoFacade,
    private estacaoFacade: EstacaoFacade, 
    private configFacade: ConfigFacade,
    private makerFactory: MapPcdMarkerFactory,
    private navigateLinkService: NavigateLinkService
  ) { 

    this.configFacade.viewMode$.subscribe(value => this.viewMode = value);
    this.dadoSensorFacade.cachedAt$.subscribe(value => this.dadoSensorCachedAt = value);
    this.instituicaoFacade.sought$.subscribe(value => this.instituicoesSought = value);

    this.mapOptions = {
      center: [-39.6, -5.2],
      zoom: (this.viewMode == ViewMode.LARGE)? 7 : 6,
      on: {
        singleclick: (estacao: DadoSensor) => {
          this.estacaoFacade.pick(estacao.codigo);
        }
      }
    }
  }

  ngOnInit() 
  {
    
  }

  ngAfterViewInit(): void {
    
    this.dadoSensorFacade.allDadoSensores$.subscribe(
      dadosSensor => {

        //COMECO DO FOREACH RESPONSAVEL POR REMOVER MOMENTANEAMENTE ELENTOS DA ESTACAO REDENCAO (4705) REFERENTE AOS SENSORES 2,3 e 4
        // dadosSensor.forEach((element, index) => {
        //   if(element.codigo == 4705) 
        //     if (element.sensor == "2" || element.sensor == "3" || element.sensor == "4") 
        //       dadosSensor.splice(index, 1);
        // })
        //FIM DO FOREACH

        this.makerFactory.plot(this.map, dadosSensor);
      }
    );

    var contextmenu = new ContextMenu({
      width: 100,
      defaultItems: false,
      items: [
        {
          text: 'Reportar',
          icon: 'assets/icon/warning_24.png', 
          callback: (evt, map) => {

            let payload = this.navigateLinkService.getPayload();
            this.dadoSensorFacade.report(
              this.contextMenuSelectedItem.codigo,
              this.contextMenuSelectedItem.sensor,
              payload.periodo,
              payload.intervalo,
              payload.operador,
              this.dadoSensorCachedAt.toISOString(),
              this.contextMenuSelectedItem.valor,
              this.contextMenuSelectedItem.unidade,
              this.instituicao.nome,
              this.instituicao.id
            );
          } 
        }
      ]
    });

    contextmenu.on('beforeopen', (evt) => {
      var feature = this.map.olMap.forEachFeatureAtPixel(evt.pixel, function (ft, l) {
        return ft;
      })
    
      if (feature) { 
        contextmenu.enable();
      } else {
        contextmenu.disable();
      }
    });

    contextmenu.on('open', (evt) => {
      var feature = this.map.olMap.forEachFeatureAtPixel(evt.pixel, function(ft, l) {
        return ft;
      });

      if (feature) {
        this.instituicoesSought.forEach(value => {
          if (value.nome == feature.values_.model.instituicao)
            this.instituicao = value;
        });

        this.contextMenuSelectedItem = feature.values_.model;
      }
    });

    this.map.olMap.addControl(contextmenu);
  }
}
